import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { PatientDto } from '@bemum/api-interfaces';
import { PatientsService } from '../../core/services/patients.service';

@Component({
  selector: 'bemum-supplements-drop-out-modal',
  templateUrl: './supplements-drop-out.component.html',
  styleUrls: ['./supplements-drop-out.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SupplementsDropOutModalComponent {
  @Input() public isVisible = false;
  @Input() public onClose: Function;
  @Input() public patient: PatientDto;

  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  otherSupplements = false;
  priceTooHigh = false;
  notFitted = false;
  wontTakeSupplements = false;
  pregnant = false;
  difficultDigest = false;
  tooMany = false;
  oftenForget = false;
  other = false;

  value = '';
  maxChar = 200;

  constructor(private patientService: PatientsService) {}

  handleOk() {
    const options = {
      otherSupplements: this.otherSupplements,
      priceTooHigh: this.priceTooHigh,
      notFitted: this.notFitted,
      wontTakeSupplements: this.wontTakeSupplements,
      pregnant: this.pregnant,
      difficultDigest: this.difficultDigest,
      tooMany: this.tooMany,
      oftenForget: this.oftenForget,
      other: this.other,
      details: this.other ? this.value : null,
    };
    this.patientService.sendSupplementsDropOut(this.patient.id, options).subscribe(
      (res) => {
        this.resetForm();
      },
      (err) => {
        console.error(err);
      }
    );
    this.onClose();
  }

  handleCancel() {
    this.onClose();
  }

  onChange(input: string): void {
    this.value = input.slice(0, this.maxChar);
    this.inputElement.nativeElement.value = this.value;
  }

  resetForm() {
    this.otherSupplements = false;
    this.priceTooHigh = false;
    this.notFitted = false;
    this.wontTakeSupplements = false;
    this.pregnant = false;
    this.difficultDigest = false;
    this.tooMany = false;
    this.oftenForget = false;
    this.other = false;
    this.value = '';
  }
}
