import {
  ApiProperty,
  IsBoolean,
  IsDefined,
  IsEmail,
  IsEnum,
  IsNotEmpty,
  IsNumber,
  IsOptional,
  IsString,
  MinLength,
  Type,
  ValidateNested,
} from './decorators-nest';
import { CreateUtmDto } from './utm.interface';

export class StripeWebhookDto {
  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  id: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  object: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  api_version: string;

  @IsNumber()
  @ApiProperty()
  created: number;

  @IsDefined()
  @ApiProperty()
  data: object;

  @IsDefined()
  @ApiProperty()
  livemode: boolean;

  @IsNumber()
  @ApiProperty()
  pending_webhooks: number;

  @IsDefined()
  @ApiProperty()
  request: object;

  @IsString()
  @ApiProperty()
  type: string;
}

export class DeleteCustomerRequestDto {
  @IsEmail()
  email: string;
}

export enum MembershipPeriod {
  MONTHLY = 'monthly',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
}

export enum ProgramType {
  ESSENTIAL = 'essentiel',
  AGE = 'age',
  SOPK = 'sopk',
}
export class CreateStripeCustomerAndSubscriptionRequestDto {
  @IsEmail()
  @ApiProperty({
    description: 'Email of the patient to create',
    example: 'my@email.com',
  })
  email: string;

  @ApiProperty({
    description: 'firstname',
    example: 'Jane',
  })
  @IsString()
  firstname: string;

  @ApiProperty({
    description: 'lastname',
    example: 'Doe',
  })
  @IsString()
  lastname: string;

  @IsString()
  @ApiProperty({
    description: 'Address Line 1 of the patient to create',
  })
  addressLine1: string;

  @IsString()
  @ApiProperty({
    description: 'Address Line 2 of the patient to create',
  })
  addressLine2: string;

  @IsString()
  @ApiProperty({
    description: 'Zip code of the patient to create',
  })
  zipCode: string;

  @IsString()
  @ApiProperty({
    description: 'City of the patient to create',
  })
  city: string;

  @IsString()
  @ApiProperty({
    description: 'Country of the patient to create',
  })
  country: string;

  @IsString()
  @ApiProperty({
    description: 'Phone number of the patient to create',
  })
  phoneNumber: string;

  @IsString()
  @ApiProperty({
    description: 'Source of the patient to create',
    example: 'Search Engine',
  })
  @IsOptional()
  source: string;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielCoaching: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplements: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withCoachingOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withStructurantOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withProtecteurOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the subscription', enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the program', enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;

  @IsString()
  @ApiProperty()
  @IsOptional()
  promotionCode?: string;
}
export class CreatePatientFromStripeCustomerDto {
  @IsEmail()
  @ApiProperty({
    description: 'Email of the patient to create',
    example: 'my@email.com',
  })
  email: string;

  @ApiProperty({
    description: 'firstname',
    example: 'Jane',
  })
  @IsString()
  firstname: string;

  @ApiProperty({
    description: 'lastname',
    example: 'Doe',
  })
  @IsString()
  lastname: string;

  @IsString()
  @MinLength(1)
  @ApiProperty({
    description: 'Password of the patient to create',
    example: 'password',
  })
  password: string;

  @IsBoolean()
  @ApiProperty({
    description: 'Tell if the patient has accepted the terms and conditions of the service',
  })
  agreestoTerms: boolean;

  @IsString()
  @ApiProperty({
    description: 'Address Line 1 of the patient to create',
  })
  addressLine1: string;

  @IsString()
  @ApiProperty({
    description: 'Address Line 2 of the patient to create',
  })
  addressLine2: string;

  @IsString()
  @ApiProperty({
    description: 'Zip code of the patient to create',
  })
  zipCode: string;

  @IsString()
  @ApiProperty({
    description: 'City of the patient to create',
  })
  city: string;

  @IsString()
  @ApiProperty({
    description: 'Country of the patient to create',
  })
  country: string;

  @IsString()
  @ApiProperty({
    description: 'Phone number of the patient to create',
  })
  phoneNumber: string;

  @IsString()
  @ApiProperty({
    description: 'Source of the patient to create',
    example: 'Search Engine',
  })
  @IsOptional()
  source: string;

  @IsNotEmpty()
  @IsString()
  @ApiProperty({
    description: 'Payment method saved by the frontend',
  })
  @IsOptional()
  paymentMethodId: string;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielCoaching: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplements: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withCoachingOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withStructurantOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withProtecteurOption: boolean;

  @IsString()
  @ApiProperty()
  @IsOptional()
  promotionCode?: string;

  @ApiProperty({
    description: 'UTM parameters that led to the purchase. Used for afilliate tracking',
    required: false,
  })
  @ValidateNested()
  @Type(() => CreateUtmDto)
  @IsOptional()
  utm?: CreateUtmDto;
}

export class CreateStripeCustomerAndCheckoutSessioDto {
  @IsEmail()
  @ApiProperty({
    description: 'Email of the patient to create',
    example: 'my@email.com',
  })
  email: string;

  @ApiProperty({
    description: 'firstname',
    example: 'Jane',
  })
  @IsString()
  firstname: string;

  @ApiProperty({
    description: 'lastname',
    example: 'Doe',
  })
  @IsString()
  lastname: string;

  @IsString()
  @ApiProperty({
    description: 'Address Line 1 of the patient to create',
  })
  addressLine1: string;

  @IsString()
  @ApiProperty({
    description: 'Address Line 2 of the patient to create',
  })
  addressLine2: string;

  @IsString()
  @ApiProperty({
    description: 'Zip code of the patient to create',
  })
  zipCode: string;

  @IsString()
  @ApiProperty({
    description: 'City of the patient to create',
  })
  city: string;

  @IsString()
  @ApiProperty({
    description: 'Country of the patient to create',
  })
  country: string;

  @IsString()
  @ApiProperty({
    description: 'Phone number of the patient to create',
  })
  phoneNumber: string;

  @IsString()
  @ApiProperty({
    description: 'Source of the patient to create',
    example: 'Search Engine',
  })
  @IsOptional()
  source: string;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielCoaching: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplements: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withCoachingOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withStructurantOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withProtecteurOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the subscription', enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the program', enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;

  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  domainURL: string;

  @IsOptional()
  @IsString()
  @ApiProperty()
  promotionCodeId: string;

  @ApiProperty({
    description: 'UTM parameters that led to the purchase. Used for afilliate tracking',
    required: false,
  })
  @ValidateNested()
  @Type(() => CreateUtmDto)
  @IsOptional()
  utm?: CreateUtmDto;
}

export class CreatePatientFromCheckoutSessionDto {
  @IsEmail()
  @ApiProperty({
    description: 'Email of the patient to create',
    example: 'my@email.com',
  })
  email: string;

  @ApiProperty({
    description: 'firstname',
    example: 'Jane',
  })
  @IsString()
  firstname: string;

  @ApiProperty({
    description: 'lastname',
    example: 'Doe',
  })
  @IsString()
  lastname: string;

  @IsString()
  @ApiProperty({
    description: 'Address Line 1 of the patient to create',
  })
  addressLine1: string;

  @IsString()
  @ApiProperty({
    description: 'Address Line 2 of the patient to create',
  })
  addressLine2: string;

  @IsString()
  @ApiProperty({
    description: 'Zip code of the patient to create',
  })
  zipCode: string;

  @IsString()
  @ApiProperty({
    description: 'City of the patient to create',
  })
  city: string;

  @IsString()
  @ApiProperty({
    description: 'Country of the patient to create',
  })
  country: string;

  @IsString()
  @ApiProperty({
    description: 'Phone number of the patient to create',
  })
  phoneNumber: string;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the subscription', enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the program', enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;
}

export class CreateCheckoutSessionDto {
  @IsNotEmpty()
  @IsString()
  @ApiProperty()
  domainURL: string;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplements: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielCoaching: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withCoachingOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withStructurantOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withProtecteurOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the subscription', enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the program', enum: ProgramType })
  @IsEnum(ProgramType)
  programType: ProgramType;
}

export class CheckoutSession {
  @IsString()
  @ApiProperty()
  url: string;
}

export class CreateSubscriptionRequestDto {
  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplements: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielCoaching: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withCoachingOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withStructurantOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withProtecteurOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the subscription', enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;
}

export class UpdateSubscriptionRequestDto {
  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withShipping: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielSupplements: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withEssentielCoaching: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withCoachingOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withStructurantOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty()
  withProtecteurOption: boolean;

  @IsNotEmpty()
  @IsDefined()
  @ApiProperty({ description: 'Type of the subscription', enum: MembershipPeriod })
  @IsEnum(MembershipPeriod)
  membershipPeriod: MembershipPeriod;
}

/** Stripe Customer Portal Session */
export interface Session {
  url: string;
}
