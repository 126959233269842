export const environment = {
  production: false,
  api_url: 'https://api-preview.bemum.co',
  crisp_website_id: '6962c3bc-844b-4c7b-abc1-5e73e7eb790c',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
