<div class="container-bemum">
  <h2 class="title-page">Mon compte</h2>
  <div class="content">
    <div nz-row [nzGutter]="[31, 24]">
      <bemum-personal-information nz-col nzXs="24" nzMd="12" nzLg="12" [patient]="patient">
      </bemum-personal-information>
      <div nz-col nzMd="12" nzLg="12">
        <bemum-subscription-billing nz-col [patient]="patient"> </bemum-subscription-billing>
        <bemum-billing-information nz-col [patient]="patient"></bemum-billing-information>
        <!-- If patient is not a stripe customer, do not display cancel subscription, patient will manage from shopify customer portal -->
        <bemum-unsubscribe-menu nz-col [patient]="patient" *ngIf="patient.stripeCustomerId"></bemum-unsubscribe-menu>
      </div>
    </div>
  </div>
</div>
