import { Component, Input, ElementRef, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { PatientDto } from '@bemum/api-interfaces';
import { PatientsService } from '../../core/services/patients.service';

@Component({
  selector: 'bemum-coaching-drop-out-modal',
  templateUrl: './coaching-drop-out.component.html',
  styleUrls: ['./coaching-drop-out.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CoachingDropOutModalComponent {
  @Input() public isVisible = false;
  @Input() public onClose: Function;
  @Input() public patient: PatientDto;

  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  notInterested = false;
  priceTooHigh = false;
  notAvailable = false;
  notHappy = false;
  other = false;

  value = '';
  maxChar = 200;

  constructor(private patientService: PatientsService) {}

  handleOk() {
    const options = {
      notInterested: this.notInterested,
      priceTooHigh: this.priceTooHigh,
      notAvailable: this.notAvailable,
      notHappy: this.notHappy,
      other: this.other,
      details: this.other ? this.value : null,
    };
    this.patientService.sendCoachingDropOut(this.patient.id, options).subscribe(
      (res) => {
        this.resetForm();
      },
      (err) => {
        console.error(err);
      }
    );
    this.onClose();
  }

  handleCancel() {
    this.onClose();
  }

  onChange(input: string): void {
    this.value = input.slice(0, this.maxChar);
    this.inputElement.nativeElement.value = this.value;
  }

  resetForm() {
    this.notInterested = false;
    this.priceTooHigh = false;
    this.notAvailable = false;
    this.notHappy = false;
    this.other = false;
    this.value = '';
  }
}
