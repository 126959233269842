<nz-modal [(nzVisible)]="isVisible" [nzFooter]="null" [nzTitle]="" (nzOnCancel)="handleCancel()" nzCentered>
  <ng-container *nzModalContent>
      <div class="modal-title">
        Pourriez-vous nous indiquer la raison pour laquelle vous ne souhaitez pas poursuivre le
        coaching ?
      </div>
    
    <div class="modal-wrapper">
      <label nz-checkbox [(ngModel)]="notInterested" class="modal-checkbox">
        Le coaching ne m'intéresse pas
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="priceTooHigh" class="modal-checkbox">
        Le prix ne me convient pas
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="notAvailable" class="modal-checkbox">
        Je ne suis pas suffisamment disponible
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="notHappy" class="modal-checkbox">
        Je ne suis pas satisfaite du coaching
      </label>
      <br />
      <label nz-checkbox [(ngModel)]="other" class="modal-checkbox">
        Autre
      </label>
      <textarea *ngIf="other" #inputElement nz-input placeholder="Veuillez préciser" [ngModel]="value" (ngModelChange)="onChange($event)"></textarea>
      <div *ngIf="other" class='max-warning'><small>Max 200 caractères</small></div>
    </div>
    
    <div class="full-center">
      <button nz-button class="bemum-purple submit-btn" nzType="default" (click)="handleOk()">Soumettre</button>
    </div>
  </ng-container>
</nz-modal>