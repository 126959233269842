import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  BillingInfo,
  CheckoutSession,
  CoachingDropOutDto,
  CreateCheckoutSessionDto,
  CreateSubscriptionRequestDto,
  PatientDto,
  ProgramDropOutDto,
  Session,
  ShopifySession,
  SupplementsDropOutDto,
  UpdatePatientDto,
} from '@bemum/api-interfaces';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PatientsService {
  constructor(private http: HttpClient) {}

  get(id: string): Observable<PatientDto> {
    return this.http.get<PatientDto>(`${environment.api_url}/patients/${id}`);
  }

  update(id: string, data: UpdatePatientDto) {
    return this.http.patch<PatientDto>(`${environment.api_url}/patients/${id}`, data);
  }

  /** @see https://api.bemum.co/docs#operation/patient-customer-portal */
  createPortalSession(id: string) {
    return this.http.post<Session>(`${environment.api_url}/patients/${id}/customer-portal-session`, null);
  }

  createCheckoutSession(id: string, data: CreateCheckoutSessionDto) {
    return this.http.post<CheckoutSession>(`${environment.api_url}/patients/${id}/checkout`, data);
  }

  /** Get Manage Subscription link for given customer */
  createShopifyPortalSession(id: string) {
    return this.http.get<ShopifySession>(`${environment.api_url}/patients/${id}/shopify-customer-portal-session`);
  }

  createShopifyCheckoutSession(id: string, data: CreateCheckoutSessionDto) {
    return this.http.post<CheckoutSession>(`${environment.api_url}/patients/${id}/shopify-checkout`, data);
  }

  createSubscription(id: string, data: CreateSubscriptionRequestDto) {
    return this.http.post(`${environment.api_url}/patients/${id}/subscription`, data, { responseType: 'text' });
  }

  updateSubscription(id: string, data: CreateSubscriptionRequestDto) {
    return this.http.put(`${environment.api_url}/patients/${id}/subscription`, data, { responseType: 'text' });
  }

  /** @see https://api.bemum.co/docs#operation/patient-billing-info */
  getBillingInfo(id: string) {
    return this.http.get<BillingInfo>(`${environment.api_url}/patients/${id}/stripe-subscription`);
  }

  /** @see https://api.bemum.co/docs#operation/patient-billing-statement */
  getBillingStatement(id: string) {
    return this.http.get(`${environment.api_url}/patients/${id}/billing-statement`, { responseType: 'blob' });
  }

  deleteSubscription(id: string) {
    return this.http.delete(`${environment.api_url}/patients/${id}/subscription`, { responseType: 'text' });
  }

  sendCoachingDropOut(id: string, data: CoachingDropOutDto) {
    return this.http.post(`${environment.api_url}/patients/${id}/coaching-drop-out`, data);
  }

  sendProgramDropOut(id: string, data: ProgramDropOutDto) {
    return this.http.post(`${environment.api_url}/patients/${id}/program-drop-out`, data);
  }

  sendSupplementsDropOut(id: string, data: SupplementsDropOutDto) {
    return this.http.post(`${environment.api_url}/patients/${id}/supplements-drop-out`, data);
  }
}
