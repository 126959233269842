<div>
  <div class="sub-content">
    <div class="sub-content-options">
      <div>
        <h5 class="label">informations personnelles</h5>
      </div>
      <div (click)="canEditPersonalInfo = true">
        <p *ngIf="!canEditPersonalInfo">Modifier</p>
      </div>
    </div>
  </div>

  <div class="content">
    <form nz-form [formGroup]="validateMainForm" (ngSubmit)="submitPersonnalInfo()">
      <div nz-row>
        <nz-form-control
          nzErrorTip="Le prénom est requis"
          nz-col
          nzSpan="12"
          style="margin-right: 26px; padding-bottom: 17px"
        >
          <input
            nz-col
            nz-input
            type="text"
            id="firstname"
            formControlName="firstname"
            placeholder="Prénom"
            [readonly]="!canEditPersonalInfo"
          />
        </nz-form-control>

        <nz-form-control nz-col nzSpan="12" nzErrorTip="Le nom est requis">
          <input
            nz-input
            type="text"
            id="lastname"
            formControlName="lastname"
            placeholder="Nom"
            [readonly]="!canEditPersonalInfo"
          />
        </nz-form-control>
      </div>

      <nz-form-control nzErrorTip="Veuillez entrer un e-mail valide">
        <input
          nz-input
          type="text"
          id="email"
          formControlName="email"
          placeholder="E-mail"
          [readonly]="!canEditPersonalInfo"
        />
      </nz-form-control>

      <div nz-row class="button-options" *ngIf="canEditPersonalInfo">
        <button
          class="button-sub-options"
          (click)="canEditPersonalInfo = false; restoreDefaultPersonalInfo()"
          type="button"
          nz-col
          nz-button
          nzType="default"
        >
          Annuler
        </button>
        <button [disabled]="validateMainForm.invalid" submit nz-col nz-button class="bemum-purple" nzType="default">
          Enregistrer
        </button>
      </div>
    </form>
  </div>

  <div class="sub-content">
    <div class="sub-content-options">
      <div>
        <h5 class="label">mot de passe</h5>
      </div>
      <div (click)="canEditPassword = true">
        <p>Modifier</p>
      </div>
    </div>
  </div>

  <div class="content">
    <form nz-form [formGroup]="validatePwdForm" (ngSubmit)="submitPasswordInfo()">
      <nz-form-control style="padding-bottom: 17px" nzErrorTip="Veuillez saisir votre mot de passe actuel">
        <input
          nz-input
          type="password"
          id="currentPwd"
          formControlName="currentPassword"
          placeholder="Mot de passe actuel"
          [readonly]="!canEditPassword"
        />
      </nz-form-control>

      <nz-form-control
        *ngIf="canEditPassword"
        style="padding-bottom: 17px"
        nzErrorTip="Votre mot de passe doit avoir au moins 8 caractères et respecter au moins 3 des conditions suivantes : une minuscule, une majuscule, un chiffre, un caractère spécial"
      >
        <input
          nz-input
          type="password"
          id="newPwd"
          formControlName="password"
          placeholder="Nouveau mot de passe"
          [readonly]="!canEditPassword"
        />
      </nz-form-control>

      <nz-form-control
        *ngIf="canEditPassword"
        style="padding-bottom: 17px"
        nzErrorTip="Le mot de passe ne correspond pas au nouveau mot de passe"
      >
        <input
          nz-input
          type="password"
          id="repeatnewPwd"
          formControlName="checkPassword"
          placeholder="Repeter nouveau mot de passe"
          [readonly]="!canEditPassword"
        />
      </nz-form-control>

      <div nz-row class="button-options" *ngIf="canEditPassword">
        <button
          class="button-sub-options"
          (click)="canEditPassword = false; restoreDefaultPassw()"
          type="button"
          nz-col
          nz-button
          nzType="default"
        >
          Annuler
        </button>
        <button [disabled]="validatePwdForm.invalid" submit nz-col nz-button class="bemum-purple" nzType="default">
          Enregistrer
        </button>
      </div>
    </form>
  </div>

  <div class="sub-content">
    <div class="sub-content-options">
      <div>
        <h5 class="label">coordonnées</h5>
      </div>
      <div (click)="canEditcontactInfo = true">
        <p>Modifier</p>
      </div>
    </div>
  </div>

  <div class="content">
    <form nz-form [formGroup]="validateContactInfoForm" (ngSubmit)="submitContactInfo()">
      <nz-form-control style="padding-bottom: 17px">
        <input
          nz-input
          type="text"
          id="phoneNumber"
          formControlName="phoneNumber"
          placeholder="Numéro de téléphone"
          [readonly]="!canEditcontactInfo"
        />
      </nz-form-control>

      <nz-form-control style="padding-bottom: 17px">
        <input
          nz-input
          type="text"
          id="address"
          formControlName="addressLine1"
          placeholder="Adresse"
          [readonly]="!canEditcontactInfo"
        />
      </nz-form-control>

      <div nz-row>
        <nz-form-control nz-col nzSpan="12" style="margin-right: 26px; padding-bottom: 17px">
          <input
            nz-input
            type="text"
            id="zipCode"
            formControlName="zipCode"
            placeholder="Code postal"
            [readonly]="!canEditcontactInfo"
          />
        </nz-form-control>

        <nz-form-control nz-col nzSpan="12" style="padding-bottom: 17px">
          <input
            nz-input
            type="text"
            id="city"
            formControlName="city"
            placeholder="Ville"
            [readonly]="!canEditcontactInfo"
          />
        </nz-form-control>
      </div>

      <nz-form-control nz-col nzSpan="12" style="padding-bottom: 17px">
        <input
          nz-input
          type="text"
          id="country"
          formControlName="country"
          placeholder="Pays"
          [readonly]="!canEditcontactInfo"
        />
      </nz-form-control>

      <div nz-row class="button-options" *ngIf="canEditcontactInfo">
        <button
          class="button-sub-options"
          (click)="canEditcontactInfo = false; restoreDefaultContactInfo()"
          type="button"
          nz-col
          nz-button
          nzType="default"
        >
          Annuler
        </button>
        <button
          [disabled]="validateContactInfoForm.invalid"
          submit
          nz-col
          nz-button
          class="bemum-purple"
          nzType="default"
        >
          Enregistrer
        </button>
      </div>
    </form>
  </div>
</div>
